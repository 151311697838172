































































































































import { useCalculation } from '@/modules/calculation';
import { defineComponent } from '@vue/composition-api';
import { getIconByProfessionCode } from '@/helpers/common';
import {
  sumMargin,
  sumTotal,
  sumProfit,
  sumHours,
  sumSelfCostMaterialsSubContractors,
} from '@/helpers/calculation';

export default defineComponent({
  setup() {
    const { projectCalculation } = useCalculation();
    return {
      projectCalculation,
      sumMargin,
      sumTotal,
      sumProfit,
      sumHours,
      sumSelfCostMaterialsSubContractors,
      getIconByProfessionCode,
    };
  },
});
