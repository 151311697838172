





















































import { useCalculation } from '@/modules/calculation';
import { useProject } from '@/modules/project';
import { defineComponent } from '@vue/composition-api';
import Loader from '@/components/loader/index.vue';
import CalculationStatus from '@/components/project-sidebar-status/calculation-status/index.vue';
import ProjectStatus from '@/components/project-sidebar-status/project-status/index.vue';
import { useGlobal } from '@/modules/global';
import {
  PROJECT_CALCULATION_OVERVIEW_PATH,
  PROJECT_CALCULATION_PATH,
} from '@/constants/routes';

export default defineComponent({
  components: {
    Loader,
    CalculationStatus,
    ProjectStatus,
  },
  setup() {
    const {
      activeProjectOverview,
      closeActiveProject,
      loadingActive,
      buildingTotals,
    } = useProject();
    const { loading, projectCalculation } = useCalculation();
    const { isOnRoute } = useGlobal();
    return {
      isOnRoute,
      closeActiveProject,
      activeProjectOverview,
      projectCalculation,
      loading,
      PROJECT_CALCULATION_PATH,
      PROJECT_CALCULATION_OVERVIEW_PATH,
      loadingActive,
      buildingTotals,
    };
  },
});
