































































































































import {
  HOME_PATH,
  PROJECT_ORGANIZATION_PATH,
  PROJECT_PRICEBOOK_PATH,
  PROJECT_ADMIN_PATH,
  PROJECT_LIBRARY_PATH,
  VERSIONS_PATH,
} from '@/constants/routes';
import { useAuth } from '@/modules/auth';
import ProjectStatus from '@/components/project-sidebar-status/index.vue';
import { defineComponent } from '@vue/composition-api';
import { useGlobal } from '@/modules/global';

export default defineComponent({
  name: 'Sidebar',
  components: {
    ProjectStatus,
  },
  props: {
    show: {
      type: Boolean,
    },
  },
  setup() {
    const { user, license } = useAuth();
    const { appVersion } = useGlobal();
    return {
      appVersion,
      HOME_PATH,
      PROJECT_ORGANIZATION_PATH,
      PROJECT_PRICEBOOK_PATH,
      PROJECT_ADMIN_PATH,
      PROJECT_LIBRARY_PATH,
      VERSIONS_PATH,
      user,
      license,
    };
  },
});
