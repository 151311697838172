










































































































































































import { defineComponent } from '@vue/composition-api';
import Loader from '@/components/loader/index.vue';
import { useProject } from '@/modules/project';
import { safePercentage } from '@/helpers/calculation';

export default defineComponent({
  components: {
    Loader,
  },
  setup() {
    const { activeProjectOverview } = useProject();
    return {
      safePercentage,
      activeProjectOverview,
    };
  },
});
